import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
    progress: Subject<number> = new Subject();

    updateProgress(progress: number): void {
        this.progress.next(progress);
    }
}