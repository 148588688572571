import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAlbum } from "./IAlbum";

@Injectable()
export class AppSettings {
	public facebook: string;
	public instagram: string;
	public linkedin: string;
	public mail: string;
	public phone: string;
	public albums: IAlbum[];
	public backgrounds: string[];

	constructor(private httpClient: HttpClient) {
	}

	initialize(): Promise<AppSettings> {
		return new Promise((resolve, reject) => {
			this.httpClient.get('assets/settings.json').subscribe((settings: AppSettings) => {
				Object.assign(this, settings);
				resolve(this);
			}, error => reject(error));
		});
	}
}
