import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { LoaderService } from './service/loader.service';

@Component({
    selector: 'app-loader',
    styleUrls: ['./loader.component.scss'],
    templateUrl: './loader.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit {
    value: number;
    isComplete: boolean;

    constructor(loaderService: LoaderService) {
        loaderService.progress.subscribe(progress => this.setValue(progress));
    }

    ngOnInit(): void {
        this.reset();
    }

    reset(): void {
        this.value = 2;
        this.isComplete = false;
    }

    private setValue(value: number): void {
        this.value = value;

        this.isComplete = this.value >= 100;
    }
}
