import { Component, OnInit, OnDestroy } from "@angular/core";
import { interval, Subscription } from 'rxjs';
import { AppSettings } from "../shared/models/AppSettings";
import { LoaderService } from '../loader/service/loader.service';

@Component({
    selector: 'app-carousel',
    styleUrls: ['./carousel.component.scss'],
    templateUrl: './carousel.component.html'
})
export class CarouselComponent implements OnInit, OnDestroy {
    private interval: Subscription;

    selectedIndex = 0;
    isLoading = true;
    images: HTMLImageElement[] = [];
    progress = 0;

    constructor(
        private settings: AppSettings,
        private loaderService: LoaderService
    ) {
        this.loadimages();
    }

    ngOnInit(): void {
        setTimeout(() => this.start(), 4500);
    }

    next(): void {
        this.increaseSelectedIndex();
        this.stop();
        this.start();
    }

    private stop(): void {
        if (!this.interval) {
            return;
        }

        this.interval.unsubscribe();
    }

    private start(): void {
        if (this.isLoading) {
            setTimeout(() => this.start(), 2000);
            return;
        }

        this.interval = interval(3500).subscribe(() => {
            this.increaseSelectedIndex();
        });
    }

    private increaseSelectedIndex(): void {
        this.selectedIndex++;

        if (this.selectedIndex === this.settings.backgrounds.length) {
            this.selectedIndex = 0;
        }
    }

    private loadimages(): void {
        if (!this.isLoading) {
            return;
        }

        let imagesLoaded = 0;

        this.settings.backgrounds.forEach(imgSrc => {
            const image = new Image();
            this.images.push(image);
            image.src = `assets/images/backgrounds/${imgSrc}`;
            image.onload = () => {
                imagesLoaded++;

                this.loaderService.updateProgress(imagesLoaded * 100 / this.settings.backgrounds.length);

                this.isLoading = imagesLoaded === this.settings.backgrounds.length ? false : true;
            };
        });
    }

    ngOnDestroy(): void {
        this.stop();
    }
}
