import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlbumComponent } from './album/album.component';
import { CarouselComponent } from './carousel/carousel.component';

export enum Modes {
    Dark,
    Light
}

const routes: Routes = [
    {
        path: '',
        component: CarouselComponent,
        data: { mode: Modes.Light }
    },
    {
        path: 'album/:albumName',
        component: AlbumComponent,
        data: { mode: Modes.Dark }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
