import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { Photo } from 'src/app/album/album.component';

@Injectable()
export class ImageService {
    photosUpdated: Subject<Photo[]> = new Subject();
    selectedIndexUpdated: Subject<number> = new Subject();

    updatePhotos(photos: Photo[]): void {
        this.photosUpdated.next(photos);
    }

    updateSelectedIndex(selectedIndex: number): void {
        this.selectedIndexUpdated.next(selectedIndex);
    }
}