import { Component, OnInit, HostListener, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ImageService } from '../shared/services/image.service';

@Component({
    selector: 'app-viewer',
    styleUrls: ['./viewer.component.scss'],
    templateUrl: './viewer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ViewerComponent implements OnInit {
    @Output() closed: EventEmitter<void> = new EventEmitter();

    selectedIndex: number;
    showViewer: boolean;
    images: HTMLImageElement[];
    disableNext: boolean;
    disablePrevious: boolean;
    panX = '0';

    @HostListener('touchmove', ['$event']) touchStart(event: TouchEvent): void {
        if (event.touches.length === 2) {
            return;
        }
        event.preventDefault();
    }

    @HostListener('document:keyup.escape', ['$event']) keyEscape() {
        this.close();
    }

    @HostListener('document:keyup.arrowleft', ['$event']) keyLeft() {
        this.previous();
    }

    @HostListener('document:keyup.arrowright', ['$event']) keyRight() {
        this.next();
    }

    constructor(
        private imageService: ImageService
    ) {
        this.imageService.photosUpdated
            .subscribe(photos => {
                this.images = photos.map(photo => photo.image);
            });

        this.imageService.selectedIndexUpdated
            .subscribe(selectedIndex => {
                this.selectedIndex = selectedIndex;
                this.showViewer = true;
                this.updateNavigation();
            });
    }

    ngOnInit(): void { }

    next(): void {
        if (this.disableNext) {
            return;
        }

        this.selectedIndex++;
        this.updateNavigation();
    }

    previous(): void {
        if (this.disablePrevious) {
            return;
        }

        this.selectedIndex--;
        this.updateNavigation();
    }

    close(): void {
        this.showViewer = false;
        this.closed.emit();
    }

    private updateNavigation(): void {
        this.disableNext = this.selectedIndex === this.images.length - 1;
        this.disablePrevious = this.selectedIndex === 0;
    }
}
