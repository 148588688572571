import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injectable } from '@angular/core';
import { MatProgressBarModule, MatIconModule, HammerManager } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSettings } from './shared/models/AppSettings';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AlbumComponent } from './album/album.component';
import { CarouselComponent } from './carousel/carousel.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/service/loader.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ViewerComponent } from './viewer/viewer.component';
import { ImageService } from './shared/services/image.service';

declare var Hammer: any;

@Injectable({ providedIn: 'root' })
export class HammerConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement): HammerManager {
        return new Hammer.Manager(element, {
            touchAction: 'auto',
            inputClass: Hammer.TouchInput,
            recognizers: [
                [Hammer.Swipe, {
                    direction: Hammer.DIRECTION_HORIZONTAL
                }],
                [Hammer.Pinch]
            ]
        });
    }
}

@NgModule({
    declarations: [
        AppComponent,
        CarouselComponent,
        AlbumComponent,
        LoaderComponent,
        ViewerComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        MatProgressBarModule,
        MatIconModule,
        DeviceDetectorModule.forRoot()
    ],
    providers: [
        AppSettings,
        LoaderService,
        ImageService,
        {
            provide: APP_INITIALIZER,
            useFactory: AppSettingsFactory,
            deps: [AppSettings, HttpClient],
            multi: true
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function AppSettingsFactory(
    appConfig: AppSettings) {
    return () => appConfig.initialize();
}

