import { Component, OnInit, SecurityContext, Inject, HostListener, ViewChild, ElementRef, ContentChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppSettings } from './shared/models/AppSettings';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Modes } from './app-routing.module';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    overmij: SafeHtml;
    mode: Modes;
    modes = Modes;
    menuIsOpen = false;
    aboutIsOpen = false;

    @ViewChild('header', { static: false }) header: ElementRef;

    @HostListener('document:keyup.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.closeMenu();
    }

    constructor(
        router: Router,
        activatedRoute: ActivatedRoute,
        public settings: AppSettings,
        private httpClient: HttpClient,
        private sanitizer: DomSanitizer,
        deviceService: DeviceDetectorService,
        @Inject(DOCUMENT) private document: Document
    ) {
        router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => activatedRoute.snapshot.firstChild.data['mode'])
        ).subscribe(mode => this.mode = mode);

        if (deviceService.isMobile() || deviceService.isTablet()) {
            this.document.body.classList.add('mobile');
        }
    }

    ngOnInit(): void {
        this.loadtext();
    }

    about(): void {
        this.aboutIsOpen = !this.aboutIsOpen;
    }

    menu(): void {
        if (!this.menuIsOpen) {
            this.menuIsOpen = !this.menuIsOpen;
            return;
        }

        this.closeMenu();
    }

    closeMenu(): void {
        this.menuIsOpen = false;
        this.aboutIsOpen = false;
        this.header.nativeElement.classList.remove('scroll');
    }

    onScroll(event: any): void {
        if (event.srcElement.scrollTop > 30) {
            this.header.nativeElement.classList.add('scroll');
        } else {
            this.header.nativeElement.classList.remove('scroll');
        }
    }

    private loadtext(): void {
        this.httpClient.get('assets/over-mij.txt', { responseType: 'text' })
            .toPromise()
            .then(response => {
                response = response.replace(/(?:\r\n|\r|\n)/g, '<br />');
                this.overmij = this.sanitizer.sanitize(SecurityContext.HTML, response);
            });
    }
}
