import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { AppSettings } from '../shared/models/AppSettings';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { IAlbum } from '../shared/models/IAlbum';
import { LoaderService } from '../loader/service/loader.service';
import { ImageService } from '../shared/services/image.service';
import { ViewerComponent } from '../viewer/viewer.component';

export class PhotoGroup {
    photos: Photo[] = [];
}

export class Photo {
    isLoaded = false;
    image: HTMLImageElement = new Image();
}

@Component({
    selector: 'app-album',
    styleUrls: ['./album.component.scss'],
    templateUrl: './album.component.html'
})
export class AlbumComponent implements OnInit {
    album: IAlbum;
    photos: Photo[];
    showAlbum = true;
    readonly imageWidth = 400;
    private readonly maxNumberOfGroups = 6;

    groups: PhotoGroup[];

    @ViewChild(ViewerComponent, { static: false }) viewer: ViewerComponent;

    @HostListener('window:resize', ['$event']) windowResize(event: any): void {
        this.initializeGroups();
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appSettings: AppSettings,
        private loaderService: LoaderService,
        private imageService: ImageService
    ) {
        route.params.subscribe(() => this.initializeAlbum());
    }

    ngOnInit(): void { }

    show(): void {
        this.showAlbum = true;
    }

    openViewer(image: HTMLImageElement): void {
        this.imageService.updatePhotos(this.photos);
        const photoIndex = this.photos.findIndex(photo => photo.image.src === image.src);
        this.imageService.updateSelectedIndex(photoIndex);
        this.showAlbum = false;
    }

    contextmenu(): boolean {
        return false;
    }

    private initializeAlbum(): void {
        const albumName = this.route.snapshot.params.albumName;
        this.album = this.appSettings.albums
            .find(album => album.name.toLocaleLowerCase() === albumName);

        if (!this.album) {
            this.router.navigate(['/']);
        }

        if (this.viewer) {
            this.viewer.close();
        }

        let photosCreated = 0;
        this.photos = this.album.photos.map(photoFileName => {
            const photo = new Photo();
            photo.image.src = `assets/images/${this.album.name.toLocaleLowerCase()}/${photoFileName}`;
            photo.image.onload = () => {
                photo.isLoaded = true;
            };
            photosCreated++;
            this.loaderService.updateProgress(photosCreated * 100 / this.album.photos.length);

            return photo;
        });

        this.initializeGroups();
    }

    private getNumberOfGroups(): number {
        if (window.innerWidth < 900) {
            return 2;
        }

        const numberOfGroups = Math.floor(window.innerWidth / this.imageWidth);

        return numberOfGroups > this.maxNumberOfGroups ? this.maxNumberOfGroups : numberOfGroups;
    }

    private initializeGroups(): void {
        const numberOfGroups = this.getNumberOfGroups();
        this.groups = [];

        for (let i = 1; i <= numberOfGroups; i++) {
            this.groups.push(new PhotoGroup());
        }

        let currentGroup = 1;

        this.photos.forEach(photo => {
            this.groups[currentGroup - 1].photos.push(photo);
            currentGroup = currentGroup === numberOfGroups ? 1 : ++currentGroup;
        });
    }
}
